<template>
  <v-card :loading="loading" :disabled="loading" class="mt-n10 moholand-shadow">
    <v-card-title>
      {{ 'ویرایش "' + title + '"' }}
      <v-spacer></v-spacer>

      <v-btn color="primary" :to="destinationRoute">
        <v-icon>mdi-undo-variant</v-icon>
        بازگشت
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent="submit">
        <v-form-base
          :col="{ cols: 12, md: 6 }"
          :model="model"
          :schema="schema"
        />
        <!-- <multi-input
          class="mt-4"
          v-model="model.images"
          :schema="imagesSchema"
          title="تصاویر"
        /> -->
        <multi-input
          class="mt-4"
          v-model="model.links"
          :schema="linksSchema"
          title="فایل ها"
        />

        <v-row align="center" justify="end" class="my-3">
          <v-btn
            class="mx-4"
            dark
            color="success"
            type="submit"
            :loading="loading"
          >
            ویرایش فایل
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import VFormBase from "vuetify-form-base";
import { getErrorText } from "@/constants/GlobalConstants";
import { formPostPrepare, formGetPrepare } from "@/constants/PanelFormHandler";
import MultiInput from "@/components/MultiInput.vue";
import moment from "jalali-moment";

export default {
  components: {
    VFormBase,
    MultiInput,
  },
  created() {
    this.getData();
    this.getFeatures();
    this.getCategories();
    this.getSoftwares();
  },

  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      title: "",
      destinationRoute:
        this.$route.fullPath.slice(
          0,
          this.$route.fullPath.indexOf("edit") - 1
        ) + (this.$route.query?.page ? "?page=" + this.$route.query?.page : ""),
      model: {
        name: "",
        slug: "",
        author_id: "",
        categories: null,
        media_id: "",
        software_id: "",
        short_desc: "",
        long_desc: "",
        links: [],
        demo: "",
        price: "",
        offer_price: "",
        end_date_offer: "",
        features: [],
        tags: [],
        // images: [],
      },
      schema: {
        name: {
          type: "CustomInput",
          label: "نام فایل",
          rules: [Constants.rules.required],
          required: true,
        },
        short_desc: {
          type: "CustomInput",
          label: "توضیحات مختصر",
          required: true,
        },
        long_desc: {
          type: "MyTinyMCE",
          label: "توضیحات",
          rules: [Constants.rules.required],
          required: true,
          col: { cols: 12 },
        },
        demo: {
          type: "MyFilePond",
          title: "دمو فایل",
          // fileTypes: "video/mp4",
          // maxFileSize: "12MB",
          files: {},
          rules: [Constants.rules.required],
          required: true,
        },
        media_id: {
          type: "MyFilePond",
          title: "تصویر پیشفرض",
          files: {},
          rules: [Constants.rules.required],
          // imageMaxWidth: 300,
          // imageMaxHeight: 300,
          required: true,
        },
        price: {
          type: "text-price-field",
          label: "قیمت",
          rules: [Constants.rules.required_price, Constants.rules.numeric],
          persistentHint: true,
          hint: "برای فایل رایگان این مقدار را 0 بگذارید.",
          required: true,
          suffix: "تومان",
        },
        offer_price: {
          type: "text-price-field",
          label: "قیمت با تخفیف",
          required: true,
          suffix: "تومان",
        },
        end_date_offer: {
          type: "ModalDatePicker",
          label: "تاریخ پایان",
          min: moment(new Date()).format("YYYY/MM/DD"),
          required: true,
        },
        features: {
          type: "CustomInput",
          label: "ویژگی ها",
          inputType: "autocomplete",
          items: [],
          itemValue: "id",
          itemText: "title",
          multiple: true,
          loading: true,
          required: true,
        },
        categories: {
          type: "CustomInput",
          label: "دسته بندی",
          inputType: "autocomplete",
          items: [],
          itemValue: "id",
          itemText: "name",
          rules: [Constants.rules.array_required],
          multiple: true,
          loading: true,
        },
        software_id: {
          type: "CustomInput",
          label: "نرم افزار",
          inputType: "autocomplete",
          items: [],
          itemValue: "id",
          itemText: "name",
          rules: [Constants.rules.required],
          loading: true,
        },
        tags: {
          type: "MyTagSelector",
          label: "تگ ها",
          rules: [Constants.rules.array_max(4)],
          col: { cols: 12 },
        },
      },
      // imagesSchema: {
      //   media_id: {
      //     type: "MyFilePond",
      //     rules: [Constants.rules.required],
      //     required: true,
      //     imageMaxWidth: 300,
      //     imageMaxHeight: 300,
      //     withDelete: false,
      //     col: { cols: 12 },
      //   },
      // },
      linksSchema: {
        media_id: {
          type: "MyFilePond",
          rules: [Constants.rules.required],
          required: true,
          fileTypes: null,
          maxFileSize: "300MB",
          withDelete: false,
          col: { cols: 12 },
          isRemote: true
        },
      },
    };
  },

  methods: {
    getData() {
      this.loading = true;
      MyAxios.get("products/show/" + this.id, {
        params: {
          noPaginate: true,
          with: ["features", "tags", "category"],
        },
      })
        .then((response) => {
          this.title = response.data.name;
          formGetPrepare(response, this.model);

          this.model.links = response.data.links_media.map((val) => ({
            media_id: val,
          }));

          // this.model.images = response.data.images_media.map((val) => ({
          //   media_id: val,
          // }));

          this.model.tags = response.data.tags.map((val) => +val.tag_id);

          this.model.features = response.data.features.map(
            (val) => +val.feature_id
          );

          this.schema.media_id.files = response.data.media || {};

          this.schema.demo.files = response.data.demo || {};

          this.model.categories = response.data.category.map(
            (val) => +val.category_id
          );

          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response || error);
          this.loading = false;
        });
    },
    getFeatures() {
      if (!this.schema.features.loading) this.schema.features.loading = true;

      MyAxios.get("/features/index", {
        params: {
          noPaginate: true,
        },
      })
        .then((response) => {
          this.schema.features.loading = false;
          this.schema.features.items = response.data;
        })
        .catch((error) => {
          this.schema.features.loading = false;
        });
    },
    getCategories() {
      if (!this.schema.tags.loading) this.schema.categories.loading = true;
      MyAxios.get("/categories/index", {
        params: {
          noPaginate: true,
          conditions: {
            parent_id: 7,
          },
        },
      })
        .then((response) => {
          this.schema.categories.loading = false;
          this.schema.categories.items = response.data;
        })
        .catch((error) => {
          this.schema.categories.loading = false;
        });
    },
    getSoftwares() {
      if (!this.schema.software_id.loading)
        this.schema.software_id.loading = true;

      MyAxios.get("/software/index", {
        params: {
          noPaginate: true,
        },
      })
        .then((response) => {
          this.schema.software_id.loading = false;
          this.schema.software_id.items = response.data.map((val) => ({
            ...val,
            name: val.name + " (" + val.format + ".)",
          }));
        })
        .catch((error) => {
          this.schema.tags.loading = false;
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        if (
          !this.model.links.filter(
            (val) => !!val?.media_id || val?.link?.length
          ).length
        ) {
          this.$root.$emit("toast", {
            text: "بخش فایل ها باید حداقل دارای یک فایل باشد.",
          });
          return;
        }

        let price = this.model.price.split(",").join("");
        let offer_price =
          this.model.offer_price && this.model.offer_price.length
            ? this.model.offer_price.split(",").join("")
            : null;

        if (offer_price && offer_price.length && +offer_price > +price) {
          this.$root.$emit("toast", {
            text: "قیمت با تخفیف نباید بیشتر از قیمت اصلی باشد!",
          });
          return;
        }
        this.loading = true;
        let data = formPostPrepare(this.model);
        data.append("slug", this.model.name.replace(/\/|\s|\./g, "-"));
        data.append("price", price);

        data.append("offer_price", offer_price);

        if (this.model.categories)
          data.append(
            "categories",
            JSON.stringify(
              this.model.categories.map((category_id) => ({ category_id }))
            )
          );

        if (this.model.features.length > 0)
          data.append(
            "features",
            JSON.stringify(
              this.model.features.map((val) => ({ feature_id: val }))
            )
          );

        if (this.model.tags.length > 0)
          data.append(
            "tags",
            JSON.stringify(this.model.tags.map((val) => ({ tag_id: val })))
          );

        if (this.model.links.length > 0)
          data.append(
            "links",
            JSON.stringify(
              this.model.links
                .filter((val) => !!val.media_id)
                .map((val) => ({
                  media_id: val.media_id.id ? val.media_id.id : val.media_id,
                }))
            )
          );

        data.append("status", 0);
        // data.append(
        //   "images",
        //   JSON.stringify(
        //     this.model.images.map((val) => ({
        //       media_id: val.media_id.id ? val.media_id.id : val.media_id,
        //     }))
        //   )
        // );

        MyAxios.post(`products/${this.id}/update`, data)
          .then((response) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "فایل با موفقیت ویرایش شد",
              type: "success",
            });
            this.$router.replace(this.destinationRoute);
          })
          .catch((error) => {
            this.loading = false;

            if (parseInt(error.response.status) === 500) {
              this.$root.$emit("toast", {
                text:
                  "اسلاگ وارد شده تکراری است یا اشکالی در ثبت اطلاعات به وجود آمده است",
              });
            } else if (parseInt(error.response.status) === 421) {
              this.$root.$emit("toast", {
                text: "اسلاگ وارد شده تکراری است. لطفا نام فایل را تغییر دهید.",
              });
            } else {
              this.$root.$emit("toast", {
                text: "مشکلی پیش آمده است. لطفا دوباره تلاش کنید.",
              });
            }
          });
      } else {
        this.$root.$emit("toast", {
          text: "لطفا اطلاعات را به درستی وارد نمایید.",
        });
      }
    },
  },
};
</script>

<style></style>
